<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="financial_account_id" label="财务账户">
          <a-select style="width:220px;" placeholder="请选择账户" v-model="financial_account_id">
            <a-select-option v-for="(item, index) in financeAccountList"
                 :value="item.financial_account_id"
                 :key="index">{{item.financial_account_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="时间">
          <a-range-picker style="width:300px"
            v-model="search_date" valueFormat="YYYY-MM-DD"/>
        </a-form-model-item>
      </a-form-model>
    </div>


    <div>
      <div class="mt-6">
        <a-button type="primary" html-type="submit" @click="handlerExport('order')">导出 线上订单流水明细</a-button>
        <span class="btn-tip">* 支付到本财务账户的线上订单的流水（用户消费支付、退款等），按费用发生时间计算</span>
      </div>
      <div class="mt-6">
        <a-button type="primary" html-type="submit" @click="handlerExport('courier')">导出 配送员业绩</a-button>
        <span class="btn-tip">* 本财务账户下属的网点的配送员的配送业绩，按送达时间计算</span>
      </div>
    </div>
		
  </div>
</template>

<script>
import { 
	financeAccountExportOrderFlow, 
	financeAccountExportVipCardFlow, 
	financeAccountExportOfflineFlow,
	financeAccountExportCourierAchievement
} from "@/api/finance/finance.js"

export default {
  components: {  },
  data() {
    return {
			search_date: [],
			financial_account_id: '',
			financeAccountList: [],

    }
  },
  mounted() {
    this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]

		try{
			let list = localStorage.getItem('financial_account_list')
					list = JSON.parse(list)
			if(list) {
				this.financeAccountList = list
				this.financial_account_id = list[0].financial_account_id
			}
		}catch(e){
			console.log(e)
		}
  },
  methods: {
    async handlerExport(type) {
			if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}

			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + '00:00:00',
				time_end:   this.search_date[1] + '23:59:59'
			}
			// 新窗口打开页面
			if(type == "order") financeAccountExportOrderFlow(params)
			if(type == "vipcard") financeAccountExportVipCardFlow(params)
			if(type == "offline") financeAccountExportOfflineFlow(params)
			if(type == "courier") financeAccountExportCourierAchievement(params)
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
.btn-tip{
	margin-left: 20px;
	font-size: 12px;
	color: #999;
}
</style>